import React, {Component} from "react"
import logo from "../img/gypsyhub-logo-white.png" 
import video from "../video/distill-cinemagraph.mp4"

import { Link } from "gatsby"

//export default function Bgvideo() {
class Bgvideo extends Component { 
    constructor(props) {
        super(props);
        this.state = {showbg:null}
        this.state = {showlogo:null}
        this.state = {shownav:null}
      }
      componentDidMount () {
        this.timeoutId = setTimeout(function () {
            this.setState({showbg: true});
        }.bind(this), 500);
        this.timeoutId2 = setTimeout(function () {
            this.setState({showlogo: true});
        }.bind(this), 1250);
        this.timeoutId3 = setTimeout(function () {
            this.setState({shownav: true});
        }.bind(this), 1250);
        const hamburger = document.getElementById('hamburger');
        const nav = document.getElementById('nav-container');
        hamburger.addEventListener("click", (event) => {
            hamburger.classList.toggle('open');
            nav.classList.toggle('open');
        });
        const parent = document.getElementById('nav--wrap--items');

        Array.prototype.forEach.call(parent.children, child => {
            child.addEventListener("click", (event) => {
                hamburger.classList.toggle('open');
                nav.classList.toggle('open');
            });
        });

      } 
    
      componentWillUnmount () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        if (this.timeoutId2) {
            clearTimeout(this.timeoutId2);
        }
        if (this.timeoutId3) {
            clearTimeout(this.timeoutId2);
        }
      }
    
      render() {
    return (
        <div id="wrapper--video" className={this.state.showbg ? 'visible-bg' : null}>
        <div className="background-video">
            
            <nav className="wrapper" id="nav-container">
                <div className="nav--wrap">
                    <div className="nav--wrap--items" id="nav--wrap--items">
                        <Link className={this.state.shownav ? 'nav-item visible' : 'nav-item'} to="/#about">About</Link>
                        <Link className={this.state.shownav ? 'nav-item visible' : 'nav-item'} to="/#services">Services</Link>
                        <Link className={this.state.shownav ? 'nav-item visible' : 'nav-item'} to="/#brands">Clients</Link>
                        <Link className={this.state.shownav ? 'nav-item visible' : 'nav-item'} to="#get-started">Get Started</Link>
                        <Link className={this.state.shownav ? 'nav-item visible' : 'nav-item'} to="#contact">Contact</Link>
                    </div>
                </div>
            </nav>
            <div className="hamburger--wrap">
                <div className="hamburger" id="hamburger"><span></span><span></span></div>
            </div>
            <h1 className="hero__title slider-tagline">
                <img className={this.state.showlogo ? 'visible' : null} src={logo} width="294" height="294" alt="contract brewing melbourne" />
            </h1>
            <video loop autoPlay muted playsInline className="video__video">
                <source src={video} type="video/mp4" />
            </video>
            <a href="#about" className="discover">
                <svg className="icon icon__down-arrow"><use xlinkHref="#down-arrow"></use></svg>
            </a>
        </div>

        </div>
    );
  }
}
export default Bgvideo