import React from "react"

import adsa from "../img/awards/adsa-18.png"
import adsabronze from "../img/awards/adsa-bronze.jpg"
import iccwssilver from "../img/awards/medal-iccws-silver.png"
import iccwsbronze from "../img/awards/medal-iccws-bronze.png"
import lscsilver from "../img/awards/lsc2021-silver.png"
import lscbronze from "../img/awards/lsc2021-bronze.png"
import miwcgold from "../img/awards/medal-miwc-gold.png"
import miwcsilver from "../img/awards/medal-miwc-silver.png"
import rrsilver from "../img/awards/medal-rr-silver.png"
import rrbronze from "../img/awards/medal-rr-bronze.png"
import womensilver from "../img/awards/womens-silver.jpg"
import womengold from "../img/awards/womens-gold.jpg"
import award1 from "../img/awards/rr-black.png"
import award2 from "../img/awards/miwc-black.png"
import award3 from "../img/awards/iccws-black.png"
import cathay from "../img/awards/cathay.jpg"
import perth from "../img/awards/perth.jpg"
import ausgold from "../img/awards/aus-gold.jpg"
import aussilver from "../img/awards/aus-silver.jpg"
import indies from "../img/awards/indies.jpg"
import taste1 from "../img/awards/best-independent-bottler.jpg"
import taste2 from "../img/awards/gold-gin.jpg"
import taste3 from "../img/awards/silver-liq.jpg"
import taste4 from "../img/awards/silver-gin.jpg"
import taste5 from "../img/awards/gold-alt-spirit.jpg"
import sfwsc19 from "../img/awards/SFWSC-Double-Gold-2-200x200.png"
import usagold from "../img/awards/usaspirits-gold.jpg"
import usasilver from "../img/awards/usaspirits-silver.jpg"
import worldgingold from "../img/awards/gold-medal-gin.jpg"
import worldginnavy from "../img/awards/worlds-best-gin.jpg"
import miwcgold2020 from "../img/awards/medal-miwc-gold2020b.png"
import miwcgold2020b from "../img/awards/medal-miwc-gold2020.png"
import aga from "../img/awards/aga-medal.png"
import cathay2 from "../img/awards/cathay-2.png"
import adsa21 from "../img/awards/ADSA-Gold-2021.png"

export default function Awards() {
    return (
            <div className="row">
                <div className="col-12">
                    <h2>Some of our award highlights:</h2>
                    <div className="clients-wrapper">
                        <img className="marquee__logo" src={adsa} alt="adsa award" />
                        <img className="marquee__logo" src={iccwssilver} alt="iccws silver medal award" />
                        <img className="marquee__logo" src={taste2} alt="gold medal gin" />
                        <img className="marquee__logo" src={sfwsc19} alt="world spirits competition double gold" />
                        <img className="marquee__logo" src={worldgingold} alt="gold medal gin" />
                        <img className="marquee__logo" src={miwcgold2020b} alt="MIWC gold 2020" />
                        <img className="marquee__logo" src={lscsilver} alt="LSC siler 2021" />
                        <img className="marquee__logo" src={aga} alt="AGA" />
                        <img className="marquee__logo" src={award3} alt="rr" />
                        <img className="marquee__logo" src={adsa21} alt="adsa21" />
                        <img className="marquee__logo" src={cathay2} alt="cathay 2021" />
                        <img className="marquee__logo" src={usagold} alt="usa spirits gold award" />
                        <img className="marquee__logo" src={indies} alt="indies brewing award" />
                        <img className="marquee__logo" src={taste1} alt="best independent bottler" />
                        <img className="marquee__logo" src={miwcgold2020} alt="MIWC gold 2020" />
                        <img className="marquee__logo" src={womengold} alt="womens wine and spirit gold award" />
                        <img className="marquee__logo" src={miwcgold} alt="melbourne international wine gold medal" />
                        <img className="marquee__logo" src={lscbronze} alt="LSC bronze 2021" />
                        <img className="marquee__logo" src={rrbronze} alt="rr bronze medal award" />
                        <img className="marquee__logo" src={taste4} alt="silver gin" />
                        <img className="marquee__logo" src={iccwsbronze} alt="iccws bronze medal award" />
                        <img className="marquee__logo" src={usasilver} alt="usaspirits gold medal award" />
                        <img className="marquee__logo" src={rrsilver} alt="rr silver medal award" />
                        <img className="marquee__logo" src={worldginnavy} alt="rr silver medal award" />
                        <img className="marquee__logo" src={taste5} alt="gold alternative spirit" />
                        <img className="marquee__logo" src={award2} alt="iccws" />
                        <img className="marquee__logo" src={taste3} alt="silver liq" />
                        <img className="marquee__logo" src={ausgold} alt="australian gin gold" />
                        <img className="marquee__logo" src={womensilver} alt="womens wine and spirit awards" />
                        <img className="marquee__logo" src={miwcsilver} alt="melbourne international wine silver medal" />
                        <img className="marquee__logo" src={adsabronze} alt="adsa bronze award" />
                        <img className="marquee__logo" src={award1} alt="miwc" />
                        <img className="marquee__logo" src={perth} alt="perth awards" />
                        <img className="marquee__logo" src={aussilver} alt="aus gin silver" />
                        <img className="marquee__logo" src={cathay} alt="cathay pacific awards" />
                    </div>
                    {/* <div className="marquee">
                        <div className="marquee__inner" aria-hidden="true">
                            <div className="marquee_1">
                                <img className="marquee__logo" src={adsa} alt="adsa award" />
                                <img className="marquee__logo" src={iccwssilver} alt="iccws silver medal award" />
                                <img className="marquee__logo" src={taste2} alt="gold medal gin" />
                                <img className="marquee__logo" src={sfwsc19} alt="world spirits competition double gold" />
                                <img className="marquee__logo" src={worldgingold} alt="gold medal gin" />
                                <img className="marquee__logo" src={miwcgold2020b} alt="MIWC gold 2020" />
                                <img className="marquee__logo" src={lscsilver} alt="LSC siler 2021" />
                                <img className="marquee__logo" src={aga} alt="AGA" />
                                <img className="marquee__logo" src={award3} alt="rr" />
                                <img className="marquee__logo" src={adsa21} alt="adsa21" />
                                <img className="marquee__logo" src={cathay2} alt="cathay 2021" />
                                <img className="marquee__logo" src={usagold} alt="usa spirits gold award" />
                                <img className="marquee__logo" src={indies} alt="indies brewing award" />
                                <img className="marquee__logo" src={taste1} alt="best independent bottler" />
                                <img className="marquee__logo" src={miwcgold2020} alt="MIWC gold 2020" />
                                <img className="marquee__logo" src={womengold} alt="womens wine and spirit gold award" />
                                <img className="marquee__logo" src={miwcgold} alt="melbourne international wine gold medal" />
                                <img className="marquee__logo" src={lscbronze} alt="LSC bronze 2021" />
                                <img className="marquee__logo" src={rrbronze} alt="rr bronze medal award" />
                                <img className="marquee__logo" src={taste4} alt="silver gin" />
                                <img className="marquee__logo" src={iccwsbronze} alt="iccws bronze medal award" />
                                <img className="marquee__logo" src={usasilver} alt="usaspirits gold medal award" />
                                <img className="marquee__logo" src={rrsilver} alt="rr silver medal award" />
                                <img className="marquee__logo" src={worldginnavy} alt="rr silver medal award" />
                                <img className="marquee__logo" src={taste5} alt="gold alternative spirit" />
                                <img className="marquee__logo" src={award2} alt="iccws" />
                                <img className="marquee__logo" src={taste3} alt="silver liq" />
                                <img className="marquee__logo" src={ausgold} alt="australian gin gold" />
                                <img className="marquee__logo" src={womensilver} alt="womens wine and spirit awards" />
                                <img className="marquee__logo" src={miwcsilver} alt="melbourne international wine silver medal" />
                                <img className="marquee__logo" src={adsabronze} alt="adsa bronze award" />
                                <img className="marquee__logo" src={award1} alt="miwc" />
                                <img className="marquee__logo" src={perth} alt="perth awards" />
                                <img className="marquee__logo" src={aussilver} alt="aus gin silver" />
                                <img className="marquee__logo" src={cathay} alt="cathay pacific awards" />
                            </div>
                            <div className="marquee_2">
                            <img className="marquee__logo" src={adsa} alt="adsa award" />
                                <img className="marquee__logo" src={iccwssilver} alt="iccws silver medal award" />
                                <img className="marquee__logo" src={taste2} alt="gold medal gin" />
                                <img className="marquee__logo" src={sfwsc19} alt="world spirits competition double gold" />
                                <img className="marquee__logo" src={worldgingold} alt="gold medal gin" />
                                <img className="marquee__logo" src={miwcgold2020b} alt="MIWC gold 2020" />
                                <img className="marquee__logo" src={lscsilver} alt="LSC siler 2021" />
                                <img className="marquee__logo" src={aga} alt="AGA" />
                                <img className="marquee__logo" src={award3} alt="rr" />
                                <img className="marquee__logo" src={adsa21} alt="adsa21" />
                                <img className="marquee__logo" src={cathay2} alt="cathay 2021" />
                                <img className="marquee__logo" src={usagold} alt="usa spirits gold award" />
                                <img className="marquee__logo" src={indies} alt="indies brewing award" />
                                <img className="marquee__logo" src={taste1} alt="best independent bottler" />
                                <img className="marquee__logo" src={miwcgold2020} alt="MIWC gold 2020" />
                                <img className="marquee__logo" src={womengold} alt="womens wine and spirit gold award" />
                                <img className="marquee__logo" src={miwcgold} alt="melbourne international wine gold medal" />
                                <img className="marquee__logo" src={lscbronze} alt="LSC bronze 2021" />
                                <img className="marquee__logo" src={rrbronze} alt="rr bronze medal award" />
                                <img className="marquee__logo" src={taste4} alt="silver gin" />
                                <img className="marquee__logo" src={iccwsbronze} alt="iccws bronze medal award" />
                                <img className="marquee__logo" src={usasilver} alt="usaspirits gold medal award" />
                                <img className="marquee__logo" src={rrsilver} alt="rr silver medal award" />
                                <img className="marquee__logo" src={worldginnavy} alt="rr silver medal award" />
                                <img className="marquee__logo" src={taste5} alt="gold alternative spirit" />
                                <img className="marquee__logo" src={award2} alt="iccws" />
                                <img className="marquee__logo" src={taste3} alt="silver liq" />
                                <img className="marquee__logo" src={ausgold} alt="australian gin gold" />
                                <img className="marquee__logo" src={womensilver} alt="womens wine and spirit awards" />
                                <img className="marquee__logo" src={miwcsilver} alt="melbourne international wine silver medal" />
                                <img className="marquee__logo" src={adsabronze} alt="adsa bronze award" />
                                <img className="marquee__logo" src={award1} alt="miwc" />
                                <img className="marquee__logo" src={perth} alt="perth awards" />
                                <img className="marquee__logo" src={aussilver} alt="aus gin silver" />
                                <img className="marquee__logo" src={cathay} alt="cathay pacific awards" />
                            </div>

                        </div>
                     </div> */}
                </div>
            </div>

    );
}
