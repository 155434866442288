import React from "react"
import marionette from "../img/clients/marionette.jpg"
import storywines from "../img/clients/story-wines.jpg"
import balcombe from "../img/clients/balcombe.jpg"
import alocalbeer from "../img/clients/a-local-beer.jpg"
import anthergin from "../img/clients/anther-gin.jpg"
import valhalla from "../img/clients/valhalla.jpg"
import sinkhole from "../img/clients/sinkhole.jpg"
import hack from "../img/clients/hack.jpg"
import mfw from "../img/clients/mfw.jpg"
import clubbrewing from "../img/clients/club-brewing.jpg"
import ester from "../img/clients/ester.jpg"
import coconspirators from "../img/clients/co-conspirators.jpg"
import greatoceanroadgin from "../img/clients/great-ocean-road-gin.jpg"
import smith from "../img/clients/smith.jpg"
import death from "../img/clients/death.jpg"
import oldwivesale from "../img/clients/old-wives-ale.jpg"
import howler from "../img/clients/howler.jpg"
import west from "../img/clients/west.jpg"
import lincoln from "../img/clients/lincoln.jpg"
import melbournetomatofest from "../img/clients/melbourne-tomato-fest.jpg"
import rouleurwine from "../img/clients/rouleur-wine.jpg"
import anatgonistgin from "../img/clients/anatgonist-gin.png"
import glenbosch from "../img/clients/glenbosch.jpg"
import heathcotegin from "../img/clients/heathcote-gin.jpg"
import mogbrewing from "../img/clients/mog-brewing.png"

import ausdriedfruits from "../img/clients/clients-2021/aus-dried-fruits.jpg"

import bimbadgen from "../img/clients/clients-2021/bimbadgen.jpg"
import bonnie from "../img/clients/clients-2021/bonnie.jpg"
import fourthwave from "../img/clients/clients-2021/fourth-wave.jpg"
import gindu from "../img/clients/clients-2021/gindu.jpg"
import grassroots from "../img/clients/clients-2021/grassroots.jpg"
import hepburn from "../img/clients/clients-2021/hepburn.jpg"
import lordhowe from "../img/clients/clients-2021/lord-howe.jpg"
import lux from "../img/clients/clients-2021/lux.jpg"
import mybrother from "../img/clients/clients-2021/my-brother.jpg"
import taka from "../img/clients/clients-2021/taka.jpg"
import tread from "../img/clients/clients-2021/tread-softly.jpg"
import moondog from "../img/clients/clients-2021/moondog.jpg"
import sippy from "../img/clients/sippy_lager.jpg"
import artillery from "../img/artillery-logo-dark.png" 
import tcc from "../img/tcc-logo.png" 
import cc from "../img/cc-logo-dark.png" 
// import casella from "../img/clients/casella.jpg" 
import miamimarketta from "../img/clients/miami-marketta.jpg" 
import boochd from "../img/clients/boochd.jpg" 
import supermate from "../img/clients/supermate.jpg" 
import wonki from "../img/clients/wonki.jpg" 
import coles from "../img/clients/coles.jpg" 
import casellaFamily from "../img/clients/casella-family.jpg" 
import beerfarm from "../img/clients/beerfarm.jpg" 


export default function Clients() {
    return (
      <div>
            <div className="row">
                <div className="col-12">
                    {/* <h2 className="h1">Contract Partners</h2> */}
                    <div className="clients-wrapper">
                        <img className="marquee__logo" src={wonki} alt="wonki" />
                        <img className="marquee__logo" src={beerfarm} alt="Beer Farm" />
                        <img className="marquee__logo" src={marionette} alt="contract distilling Melbourne" />
                        <img className="marquee__logo" src={casellaFamily} alt="Casella Family" />
                        <img className="marquee__logo" src={balcombe} alt="Balcombe Gin" />
                        <img className="marquee__logo" src={coles} alt="Coles" />
                        <img className="marquee__logo" src={boochd} alt="Boochd" />
                        <img className="marquee__logo" src={supermate} alt="Supermate" />
                        <img className="marquee__logo" src={moondog} alt="Moon Dog Brewery" />
                        <img className="marquee__logo" src={anthergin} alt="Anther Gin" />
                        <img className="marquee__logo" src={sippy} alt="Sippy Lager" />
                        <img className="marquee__logo" src={cc} alt="Sippy Lager" />
                        <img className="marquee__logo" src={storywines} alt="Story Wines" />
                        <img className="marquee__logo" src={ester} alt="Ester Gin" />
                        {/* <img className="marquee__logo" src={casella} alt="Ester Gin" /> */}
                        <img className="marquee__logo" src={tread} alt="Tread Softly" />
                        <img className="marquee__logo" src={artillery} alt="Artillery" />
                        <img className="marquee__logo" src={valhalla} alt="Valhalla" />
                        <img className="marquee__logo" src={sinkhole} alt="Sinkhole" />
                        <img className="marquee__logo" src={hack} alt="hack" />
                        <img className="marquee__logo" src={mfw} alt="Melbourne food and wine" />
                        <img className="marquee__logo" src={clubbrewing} alt="Club Brewing" />
                        <img className="marquee__logo" src={miamimarketta} alt="Miami Marketta" />
                        <img className="marquee__logo" src={coconspirators} alt="Co-conspirators" />
                        <img className="marquee__logo" src={grassroots} alt="grassroots" />
                        <img className="marquee__logo" src={melbournetomatofest} alt="Melbourne Tomato Festival" />
                        <img className="marquee__logo" src={rouleurwine} alt="Rouleur Wine Co" />
                        <img className="marquee__logo" src={anatgonistgin} alt="Anatgonist Gin" />
                        <img className="marquee__logo" src={glenbosch} alt="Glenbosch" />
                        <img className="marquee__logo" src={bonnie} alt="Bonnie" />
                        <img className="marquee__logo" src={mogbrewing} alt="Mog Brewing" />
                        <img className="marquee__logo" src={greatoceanroadgin} alt="Great Ocean Road Gin" />
                        <img className="marquee__logo" src={smith} alt="Smith Journal" />
                        <img className="marquee__logo" src={death} alt="Death Gin" />
                        <img className="marquee__logo" src={tcc} alt="hack" />
                        <img className="marquee__logo" src={oldwivesale} alt="Old Wives Ale" />
                        <img className="marquee__logo" src={gindu} alt="Gindu" />
                        <img className="marquee__logo" src={howler} alt="Howler" />
                        <img className="marquee__logo" src={ausdriedfruits} alt="australia dried fruits" />
                        <img className="marquee__logo" src={west} alt="West City Brewing" />
                        <img className="marquee__logo" src={lincoln} alt="Lincoln" />
                        <img className="marquee__logo" src={bimbadgen} alt="Bimbadgen" />
                        <img className="marquee__logo" src={heathcotegin} alt="Heathcote Gin" />
                        <img className="marquee__logo" src={fourthwave} alt="Fourth Wave" />
                        <img className="marquee__logo" src={alocalbeer} alt="A Local Beer" />
                        <img className="marquee__logo" src={hepburn} alt="Hepburn Brewing" />
                        <img className="marquee__logo" src={lordhowe} alt="Lord Howe" />
                        <img className="marquee__logo" src={lux} alt="Lux" />
                        <img className="marquee__logo" src={mybrother} alt="My Brother" />
                        <img className="marquee__logo" src={taka} alt="taka" />
                    </div>
                    {/* <div className="marquee">
                        <div className="marquee__inner" aria-hidden="true">
                            <div className="marquee_1">
                            <img className="marquee__logo" src={marionette} alt="contract distilling Melbourne" />
                            <img className="marquee__logo" src={storywines} alt="Story Wines" />
                            <img className="marquee__logo" src={balcombe} alt="Balcombe Gin" />
                            <img className="marquee__logo" src={gindispensary} alt="Gin Dispensary" />
                            <img className="marquee__logo" src={artillery} alt="Gin Dispensary" />
                            <img className="marquee__logo" src={moondog} alt="Moon Dog Brewery" />
                            <img className="marquee__logo" src={anthergin} alt="Anther Gin" />
                            <img className="marquee__logo" src={sippy} alt="Sippy Lager" />
                            <img className="marquee__logo" src={cc} alt="Sippy Lager" />
                            <img className="marquee__logo" src={ester} alt="Ester Gin" />
                            <img className="marquee__logo" src={casella} alt="Ester Gin" />
                            <img className="marquee__logo" src={tread} alt="Tread Softly" />
                            <img className="marquee__logo" src={valhalla} alt="Valhalla" />
                            <img className="marquee__logo" src={sinkhole} alt="Sinkhole" />
                            <img className="marquee__logo" src={hack} alt="hack" />
                            <img className="marquee__logo" src={mfw} alt="Melbourne food and wine" />
                            <img className="marquee__logo" src={clubbrewing} alt="Club Brewing" />
                            <img className="marquee__logo" src={coconspirators} alt="Co-conspirators" />
                            <img className="marquee__logo" src={grassroots} alt="grassroots" />
                            <img className="marquee__logo" src={melbournetomatofest} alt="Melbourne Tomato Festival" />
                            <img className="marquee__logo" src={rouleurwine} alt="Rouleur Wine Co" />
                            <img className="marquee__logo" src={anatgonistgin} alt="Anatgonist Gin" />
                            <img className="marquee__logo" src={glenbosch} alt="Glenbosch" />
                            <img className="marquee__logo" src={bonnie} alt="Bonnie" />
                            <img className="marquee__logo" src={mogbrewing} alt="Mog Brewing" />
                          </div>
                          <div className="marquee_2">
                            <img className="marquee__logo" src={marionette} alt="contract distilling Melbourne" />
                            <img className="marquee__logo" src={storywines} alt="Story Wines" />
                            <img className="marquee__logo" src={balcombe} alt="Balcombe Gin" />
                            <img className="marquee__logo" src={gindispensary} alt="Gin Dispensary" />
                            <img className="marquee__logo" src={artillery} alt="Gin Dispensary" />
                            <img className="marquee__logo" src={moondog} alt="Moon Dog Brewery" />
                            <img className="marquee__logo" src={anthergin} alt="Anther Gin" />
                            <img className="marquee__logo" src={sippy} alt="Sippy Lager" />
                            <img className="marquee__logo" src={cc} alt="Sippy Lager" />
                            <img className="marquee__logo" src={ester} alt="Ester Gin" />
                            <img className="marquee__logo" src={casella} alt="Ester Gin" />
                            <img className="marquee__logo" src={tread} alt="Tread Softly" />
                            <img className="marquee__logo" src={valhalla} alt="Valhalla" />
                            <img className="marquee__logo" src={sinkhole} alt="Sinkhole" />
                            <img className="marquee__logo" src={hack} alt="hack" />
                            <img className="marquee__logo" src={mfw} alt="Melbourne food and wine" />
                            <img className="marquee__logo" src={clubbrewing} alt="Club Brewing" />
                            <img className="marquee__logo" src={coconspirators} alt="Co-conspirators" />
                            <img className="marquee__logo" src={grassroots} alt="grassroots" />
                            <img className="marquee__logo" src={melbournetomatofest} alt="Melbourne Tomato Festival" />
                            <img className="marquee__logo" src={rouleurwine} alt="Rouleur Wine Co" />
                            <img className="marquee__logo" src={anatgonistgin} alt="Anatgonist Gin" />
                            <img className="marquee__logo" src={glenbosch} alt="Glenbosch" />
                            <img className="marquee__logo" src={bonnie} alt="Bonnie" />
                            <img className="marquee__logo" src={mogbrewing} alt="Mog Brewing" />
                            </div>
                        </div>
                     </div> */}
                </div>
            </div>

            {/* <div className="row">
                <div className="col-12">
                    <div className="marquee clients-second-line">
                        <div className="marquee__inner" aria-hidden="true">
                            <div className="marquee_1">
                            <img className="marquee__logo" src={greatoceanroadgin} alt="Great Ocean Road Gin" />
                            <img className="marquee__logo" src={smith} alt="Smith Journal" />
                            <img className="marquee__logo" src={death} alt="Death Gin" />
                            <img className="marquee__logo" src={tcc} alt="hack" />
                            <img className="marquee__logo" src={oldwivesale} alt="Old Wives Ale" />
                            <img className="marquee__logo" src={gindu} alt="Gindu" />
                            <img className="marquee__logo" src={howler} alt="Howler" />
                            <img className="marquee__logo" src={ausdriedfruits} alt="australia dried fruits" />
                            <img className="marquee__logo" src={west} alt="West City Brewing" />
                            <img className="marquee__logo" src={lincoln} alt="Lincoln" />
                            <img className="marquee__logo" src={bimbadgen} alt="Bimbadgen" />
                            <img className="marquee__logo" src={heathcotegin} alt="Heathcote Gin" />
                            <img className="marquee__logo" src={fourthwave} alt="Fourth Wave" />
                            <img className="marquee__logo" src={alocalbeer} alt="A Local Beer" />
                            <img className="marquee__logo" src={hepburn} alt="Hepburn Brewing" />
                            <img className="marquee__logo" src={lordhowe} alt="Lord Howe" />
                            <img className="marquee__logo" src={lux} alt="Lux" />
                            <img className="marquee__logo" src={mybrother} alt="My Brother" />
                            <img className="marquee__logo" src={taka} alt="taka" />
                            </div>
                            <div className="marquee_2">
                            <img className="marquee__logo" src={greatoceanroadgin} alt="Great Ocean Road Gin" />
                            <img className="marquee__logo" src={smith} alt="Smith Journal" />
                            <img className="marquee__logo" src={death} alt="Death Gin" />
                            <img className="marquee__logo" src={tcc} alt="hack" />
                            <img className="marquee__logo" src={oldwivesale} alt="Old Wives Ale" />
                            <img className="marquee__logo" src={gindu} alt="Gindu" />
                            <img className="marquee__logo" src={howler} alt="Howler" />
                            <img className="marquee__logo" src={ausdriedfruits} alt="australia dried fruits" />
                            <img className="marquee__logo" src={west} alt="West City Brewing" />
                            <img className="marquee__logo" src={lincoln} alt="Lincoln" />
                            <img className="marquee__logo" src={bimbadgen} alt="Bimbadgen" />
                            <img className="marquee__logo" src={heathcotegin} alt="Heathcote Gin" />
                            <img className="marquee__logo" src={fourthwave} alt="Fourth Wave" />
                            <img className="marquee__logo" src={alocalbeer} alt="A Local Beer" />
                            <img className="marquee__logo" src={hepburn} alt="Hepburn Brewing" />
                            <img className="marquee__logo" src={lordhowe} alt="Lord Howe" />
                            <img className="marquee__logo" src={lux} alt="Lux" />
                            <img className="marquee__logo" src={mybrother} alt="My Brother" />
                            <img className="marquee__logo" src={taka} alt="taka" />
                            </div>
                        </div>
                     </div>
                </div>
            </div> */}

          </div>

    );
}
