import React from "react"
import { navigate } from 'gatsby'
import addToMailchimp from 'gatsby-plugin-mailchimp'
export default class MailChimpForm extends React.Component{
    // Since `addToMailchimp` returns a promise, you
    // can handle the response in two different ways:

    // Note that you need to send an email & optionally, listFields
    // these values can be pulled from React state, form fields,
    // or wherever.  (Personally, I recommend storing in state).
    state = {
        FIRSTNAME: null,
        SURNAME: null,
        COMPANY: null,
        POSITION: null,
        CITY: null,
        STATE: null,
        EMAIL: null,
        PHONE: null,
        ADDITIONAL: null,
        OTHERCONTR: null,
        OTHERPRODU: null,
        checkCount: 0,
        checkboxValid: false,
        message: '',
        // 'group[4808][1]': '1',
        // 'group[4808][2]': '2',
        // 'group[4808][4]': '4',
        // 'group[4808][8]': '8',
        // 'group[4808][16]': '16',
        // 'group[4808][32]': '32',
        // 'group[4808][64]': '64',
        // 'group[4808][128]': '128',
        // 'group[4808][256]': '256',
        // 'group[4808][512]': '512',
        // 'group[4808][1024]': '1024',
        // 'group[4808][2048]': '2048',
        // 'group[4808][4096]': '4096',
        // 'group[4808][8192]': '8192',
        // 'group[4808][16384]': '16384',
        // 'group[4808][32768]': '32768',
        // 'group[4812][65536]': '65536',
        // 'group[4812][131072]': '131072',
        // 'group[4812][262144]': '262144',
        // 'group[4812][524288]': '524288',
        // 'group[4812][1048576]': '1048576',
        // 'group[4816][2097152]': '2097152',
        // 'group[4816][4194304]': '4194304',
        // 'group[4816][8388608]': '8388608',
        // 'group[4816][16777216]': '16777216',
        // 'group[4816][33554432]': '33554432',
    }

    _handleChange = e => {
        // console.log({
        //     [`${e.target.name}`]: e.target.value,
        // })
        this.setState({
            [`${e.target.name}`]: e.target.value,
        })
    }

    _handleCheckboxChange = e => {
      // console.log({
      //     [`${e.target.name}`]: e.target.value,
      // })
      if (e.target.checked) {
        this.setState(prevState => ({
          checkCount: prevState.checkCount + 1,
          checkboxValid: true
        }));
      } else {
        this.setState(
          prevState => ({ checkCount: prevState.checkCount - 1 }),
          () => {
            if (this.state.checkCount === 0)
              this.setState({ checkboxValid: false });
          }
        );
      }


      this.setState({
          [`${e.target.name}`]: e.target.value,
      })
  }

    _handleSubmit = e => {
        e.preventDefault();
        //console.log('submit', this.state)
        if (this.state.checkCount === 0){
          this.setState({message: 'Please select at least one product of interest'})
        } else{
          this.setState({ message: '' })
          addToMailchimp(
            this.state.EMAIL, this.state )

        .then(({ msg, result }) => {
           navigate ('thank-you')

        })
      }

      }
    render () {
        return (
        <form method="post" onSubmit={this._handleSubmit} id="get-started-form" className="get-started-form">
            <div className="row">
              <div className="col-6 col-12-sm get-started-form--main">
              <fieldset>
              <legend>Your details:</legend>

                <input placeholder="First Name*" type="text" name="FIRSTNAME" id="firstname" onChange={this._handleChange} required />
                <input placeholder="Surname*" type="text" name="SURNAME" id="surname" onChange={this._handleChange} required />
                <input placeholder="Company*" type="text" name="COMPANY" id="company" onChange={this._handleChange} required />
                <input placeholder="Position" type="text" name="POSITION" id="position" onChange={this._handleChange} />
                <input placeholder="Email*" type="email" name="EMAIL" id="email" onChange={this._handleChange} required />
                <input placeholder="Phone*" type="number" name="PHONE" id="phone" onChange={this._handleChange} required />
                <input placeholder="City" type="text" name="CITY" id="city" onChange={this._handleChange} />
                <input placeholder="State" type="text" name="STATE" id="state" onChange={this._handleChange} />
                <textarea placeholder="Additional Information*" name="ADDITIONAL" id="message" rows="11" onChange={this._handleChange} required />

              </fieldset>
              </div>
              <div className="col-6 col-12-sm get-started-form--secondary">
              <fieldset>
              <legend>Select your products of interest:*</legend>
                <div className="wrapper--checkboxes">
                  <div className="wrapper--grouping">
                    <h3 className="wrapper--grouping--heading">White Label Options</h3>
                    <h4 className="wrapper--grouping--heading">Gin or Vodka</h4>
                      <div className="wrapper--checkbox">
                        <label htmlFor="gin-white-500"><input type="checkbox" value="67108864" name="group[4916][67108864]" id="gin-white-500" onChange={this._handleCheckboxChange} />500-1499L</label>
                      </div>
                      <div className="wrapper--checkbox">
                        <label htmlFor="gin-white-1500"><input type="checkbox" value="134217728" name="group[4916][134217728]" id="gin-white-1500" onChange={this._handleCheckboxChange} />1500-4999L</label>
                      </div>
                      <div className="wrapper--checkbox">
                        <label htmlFor="gin-white-5000"><input type="checkbox" value="268435456" name="group[4916][268435456]" id="gin-white-5000" onChange={this._handleCheckboxChange} />5000-9999L</label>
                      </div>
                      <div className="wrapper--checkbox">
                        <label htmlFor="gin-white-10000"><input type="checkbox" value="536870912" name="group[4916][536870912]" id="gin-white-10000" onChange={this._handleCheckboxChange} />10,000L+</label>
                      </div>
                    </div>
                    <div className="wrapper--grouping">
                      <div className="wrapper--checkbox">
                        <label htmlFor="seltzer"><input type="checkbox" value="1073741824" name="group[4916][1073741824]" id="seltzer" onChange={this._handleCheckboxChange} />Seltzer <em>(min 6000L)</em></label>
                      </div>
                      <div className="wrapper--checkbox">
                        <label htmlFor="water"><input type="checkbox" value="2147483648" name="group[4916][2147483648]" id="water" onChange={this._handleCheckboxChange} />Water <em>(min 6000L)</em></label>
                      </div>
                      <div className="wrapper--checkbox">
                        <label htmlFor="beer"><input type="checkbox" value="4294967296" name="group[4916][4294967296]" id="beer" onChange={this._handleCheckboxChange} />Beer <em>(min 4000L)</em></label>
                      </div>
                      <div className="wrapper--checkbox">
                        <label htmlFor="soft-drink"><input type="checkbox" value="8589934592" name="group[4916][8589934592]" id="soft-drink" onChange={this._handleCheckboxChange} />Soft Drink <em>(min 6000L)</em></label>
                      </div>
                      <div className="wrapper--checkbox">
                        <label htmlFor="wine"><input type="checkbox" value="17179869184" name="group[4916][17179869184]" id="wine" onChange={this._handleCheckboxChange} />Wine <em>(min 4,000L)</em></label>
                      </div>
                    </div>
                    <div className="wrapper--grouping">
                      <h3 className="wrapper--grouping--heading">Bespoke Options</h3>
                      <h4 className="wrapper--grouping--heading">Gin or Vodka</h4>
                      <div className="wrapper--checkbox">
                        <label htmlFor="gin-bespoke-3500"><input type="checkbox" value="34359738368" name="group[4916][34359738368]" id="gin-bespoke-3500" onChange={this._handleCheckboxChange} />3500-4999L</label>
                      </div>
                      <div className="wrapper--checkbox">
                        <label htmlFor="gin-bespoke-5000"><input type="checkbox" value="68719476736" name="group[4916][68719476736]" id="gin-bespoke-5000" onChange={this._handleCheckboxChange} />5000-9999L</label>
                      </div>
                      <div className="wrapper--checkbox">
                        <label htmlFor="gin-bespoke-10000"><input type="checkbox" value="137438953472" name="group[4916][137438953472]" id="gin-bespoke-10000" onChange={this._handleCheckboxChange} />10,000-19,999L</label>
                      </div>
                      <div className="wrapper--checkbox">
                        <label htmlFor="gin-bespoke-20000"><input type="checkbox" value="274877906944" name="group[4916][274877906944]" id="gin-bespoke-20000" onChange={this._handleCheckboxChange} />20,000L+</label>
                      </div>
                    </div>
                    <div className="wrapper--grouping">
                      <h4 className="wrapper--grouping--heading">Spirits</h4>
                      <div className="wrapper--checkbox">
                        <label htmlFor="rum"><input type="checkbox" value="549755813888" name="group[4916][549755813888]" id="rum" onChange={this._handleCheckboxChange} />Rum <em>(3500L – 20,000L+)</em></label>
                      </div>
                      <div className="wrapper--checkbox">
                        <label htmlFor="whisky"><input type="checkbox" value="1099511627776" name="group[4916][1099511627776]" id="whisky" onChange={this._handleCheckboxChange} />Whisky <em>(3500L – 20,000L+)</em></label>
                      </div>
                      <div className="wrapper--checkbox">
                        <label htmlFor="liqueur"><input type="checkbox" value="2199023255552" name="group[4916][2199023255552]" id="liqueur" onChange={this._handleCheckboxChange} />Liqueur <em>(3500L – 20,000L+)</em></label>
                      </div>
                      <div className="wrapper--checkbox">
                        <label htmlFor="rtd"><input type="checkbox" value="4398046511104" name="group[4916][4398046511104]" id="rtd" onChange={this._handleCheckboxChange} />RTD <em>(3500L – 20,000L+)</em></label>
                      </div>
                    </div>
                    <div className="wrapper--grouping">
                      <h4 className="wrapper--grouping--heading">Beer</h4>
                      <div className="wrapper--checkbox">
                        <label htmlFor="beer-bespoke"><input type="checkbox" value="8796093022208" name="group[4916][8796093022208]" id="beer-bespoke" onChange={this._handleCheckboxChange} />Beer <em>(min 4000L)</em></label>
                      </div>
                    </div>

                    <div className="wrapper--grouping">
                      <div className="wrapper--checkbox">
                        <label htmlFor="contract-packaging"><input type="checkbox" value="17592186044416" name="group[4916][17592186044416]" id="contract-packaging" onChange={this._handleCheckboxChange} />Contract Packaging</label>
                        <input placeholder="Please specify*" type="text" name="OTHERCONTR" id="contract-packaging-input" onChange={this._handleChange} />
                      </div>
                      <div className="wrapper--checkbox">
                        <label htmlFor="other-product"><input type="checkbox" value="35184372088832" name="group[4916][35184372088832]" id="other-product" onChange={this._handleCheckboxChange} />Other</label>
                        <input placeholder="Please specify*" type="text" name="OTHERPRODU" id="other-product-input" onChange={this._handleChange} />
                      </div>
                    </div>

                </div>

              </fieldset>

              </div>
              </div>
              <input type="text" className="gotcha" defaultValue="" name="_empty_field"/>
                <div className="wrapper--checkbox get-started-form--newsletter">
                  <label htmlFor="newsletter"><input type="checkbox" name="Subscribe-to-Newsletter" id="newsletter" defaultChecked />Keep me up to date on all the latest news, events and offers</label>
                </div>

              <div style={{position: `absolute`, left: `-5000px`}} aria-hidden="true"><input type="text" name="b_96f48b0dfd1a6043590773ac4_adcba87257" tabIndex="-1" defaultValue="" /></div>
              <p className="error--checkbox">{this.state.message}</p>
              <input type="submit" defaultValue="Submit" className="get-started-form--submit" />
              </form>
        )
    }
}


